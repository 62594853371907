
  .link {
    display: block !important;
    text-decoration: none;
    color: white;
    font-size: 20px;
    margin: 20px;
  }
  .nav-links {
    margin-left: 20px;
    display: flex;
    flex-grow: 1;
    justify-content: space-around;
  }
.logo {
    cursor: pointer;
}