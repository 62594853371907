.LogoutButton {
    color: white;
    font-size: 20px;
    margin-left: 160px;
    text-decoration: none;
    display: flex;
    align-items: center;
}
.LogoutButton b {
    font-weight:normal;
    cursor: pointer;
}